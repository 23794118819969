export default function userIs(...arrUsers) {
  const userData = JSON.parse(localStorage.getItem('user'));
  const role = userData.role;
  const lowerUsers = arrUsers.map((v) => v.toLowerCase());
  let userType = '';

  if (role.roleId !== 2) {
    userType = role.roleName;
  } else {
    userType = `administrador de ${userData.area}`;
  }
  return lowerUsers.includes(userType.toLowerCase());
}
