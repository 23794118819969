import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Radio } from 'antd';
import DownloadReport from './DownloadReport';
import { setValidationsFieldsRequest } from 'Domains/Validations/redux-saga/actions/validationFields';

const validationOptions = [
  {
    label: 'Desafío de ventas',
    value: 'salesChallenge',
  },
  {
    label: 'Acuerdo comercial Sell In',
    value: 'tradeAgreement',
  },
  {
    label: 'Acuerdo comercial Sell Out',
    value: 'tradeAgreementSellOut',
  },
  /* {
    label: 'Todos',
    value: 'All',
  }, */
];
const ValidationHeader = () => {
  const validationsFields = useSelector(
    (state) => state.VALIDATIONS.validationFieldsReducer.validationFields
  );
  const dispatch = useDispatch();

  const onChangeFilter = (value) => {
    dispatch(setValidationsFieldsRequest(value));
  };

  return (
    <>
      <section className="validation-type__wrapper">
        <h4> Agregar validador </h4>
        <Radio.Group
          options={validationOptions}
          onChange={(e) => {
            onChangeFilter(e.target.value);
          }}
          value={validationsFields}
        />
        <DownloadReport />
      </section>
    </>
  );
};

export default ValidationHeader;
