import { takeLatest, put, call, select } from 'redux-saga/effects';
import instance from 'services/request';
import {
  DELETE_TRADE_AGREEMENT_SELL_OUT_VALIDATOR_REQUEST,
  deleteTradeAgreementSellOutValidatorSuccess,
  deleteTradeAgreementSellOutValidatorFail,
} from '../actions/deleteTradeAgreementSellOutValidator';
//import { mockAddTradeAgreementValidator } from '../../services/addTradeAgreementValidator';
import { openNotification } from 'common/misc/openNotification';
import { responseOK } from 'utils';
import { validatorsListTradeAgreementSellOutRequest } from '../actions/validatorList';
import { clientsRequest } from 'RootModule/redux/actions/catalogs';
import { deleteTradeAgreementSellOutValidator } from 'Domains/Validations/services/deleteTradeAgreementSelloutValidator';
import { tradeAgreementsSellOutValidatorsCatalogRequest } from 'RootModule/redux/actions/tradeAgreementsSellOutValidators';

function* workDeleteTradeAgreementSellOutValidator(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { params } = action.payload;
    const response = yield call(deleteTradeAgreementSellOutValidator, params, source.token);
    const query = yield select((state) => state?.VALIDATIONS?.filters?.query);
    const paramsToGetValidators = { Territories: query.territoryId, Clients: query.clientId };
    const requestParams = {
      lineId: query.lineId,
      subRegionId: query.subRegionId,
      territoryId: query.territoryId,
    };
    const sourceId = 'Validators';

    if (responseOK(response)) {
      yield put(deleteTradeAgreementSellOutValidatorSuccess());
      openNotification(
        'success',
        response.message
          ? response.message
          : 'La eliminación del usuario validador se realizó correctamente.'
      );
      yield put(
        clientsRequest({
          requestParams,
          sourceId,
          mode: 'multiple',
        })
      );
      yield put(validatorsListTradeAgreementSellOutRequest({ params: paramsToGetValidators }));
      yield put(tradeAgreementsSellOutValidatorsCatalogRequest({ params: paramsToGetValidators }));
    } else {
      openNotification(
        'error',
        response.message ? response.message : 'No se pudo realizar la operación'
      );
    }
  } catch (error) {
    yield put(deleteTradeAgreementSellOutValidatorFail());
    console.error(error);
  }
}

export function* watchDeleteTradeAgreementSellOutValidator() {
  yield takeLatest(
    DELETE_TRADE_AGREEMENT_SELL_OUT_VALIDATOR_REQUEST,
    workDeleteTradeAgreementSellOutValidator
  );
}
