import { takeLatest, put, call, cancelled, delay, select } from 'redux-saga/effects';
import {
  APPROVE_TRADE_AGREEMENT_REQUEST,
  approveTradeAgreementSuccess,
  approveTradeAgreementAgreementFail,
  REJECT_TRADE_AGREEMENT_REQUEST,
  rejectTradeAgreementSuccess,
  rejectTradeAgreementegreementFail,
  APPROVE_TRADE_AGREEMENT_KAM_REQUEST,
  approveTradeAgreementSuccessByKAM,
  approveTradeAgreementAgreementFailByKAM,
  REJECT_TRADE_AGREEMENT_KAM_REQUEST,
  rejectTradeAgreementSuccessByKAM,
  rejectTradeAgreementegreementFailByKAM,
  GENERATE_TRADE_AGREEMENT_REQUEST,
  generateradeAgreementSuccess,
  generateTradeAgreementAgreementFail,
} from '../actions/agreement';

import {
  approveTradeAgreement,
  rejectTradeAgreement,
  approveTradeAgreementByKam,
  rejectTradeAgreementByKam,
  generateAgreement,
} from '../../services/agreement';
import { clientSelected, saveClient } from '../actions/infoForAgreement';
import { getIndicator } from '../../services/indicators';
import { responseOK } from '../../utils';
import { openNotification } from 'common/misc/openNotification';
import { getFromStore } from './selectors';
import { updateClients } from 'RootModule/redux/actions/catalogs';
import { orderArrayObjects } from 'Domains/SalesChallengeRefactor/utils';
import { indicatorsRequest, indicatorsSuccess } from '../actions/indicators';
import { roleIdEnum } from 'common/models/enums';
import { familyRequest, infoRequest, secondValidationValidUserRequest } from '../actions';
import instance from 'services/request';
const { KAM } = roleIdEnum;

function* workerApproveTradeAgreementRequest(action) {
  const currentIndicatorstClient = yield select((state) =>
    getFromStore(state, 'TRADE_AGREEMENTS_SELLOUT', 'views', 'indicators')
  );
  const currentTradeAgreementClients = yield select((state) =>
    getFromStore(state, 'TRADE_AGREEMENTS_SELLOUT', 'infoForAgreement', 'clientInfo')
  );

  //? Request params for success could be obtained from the store
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { data } = payload;
    const { clientId, territoryId, year } = data;
    const params = { clientId, territoryId, year };

    const response = yield call(approveTradeAgreement, {
      data,
      cancelToken: source.token,
    });
    if (responseOK(response)) {
      const currentClients = yield select((state) => state?.APP?.catalogs?.clients?.data);
      let currentClient = data?.clientId;
      let found = currentClients.find((client) => client.key === currentClient);
      let rest = currentClients.filter((client) => client.key !== currentClient);
      if (found) {
        let updateClient = { ...found, statusId: 4 };
        let newClients = [...rest, updateClient];
        let orderClients = newClients.sort(orderArrayObjects('place'));
        yield put(updateClients(orderClients));
      }

      yield openNotification('success', response.message);
      yield delay(200);
      yield put(approveTradeAgreementSuccess(response.data));

      // let paramsInfoRequest = {
      //   name: 'client',
      //   params: { clientId, territoryId, year },
      //   sourceId: role?.roleId === KAM ? 'KAM' : null,
      // };

      // let paramsIndicators = { name: 'client', params: { territoryId, clientId, year } };
      // yield put(indicatorsRequest(paramsIndicators));
      // yield put(infoRequest(paramsInfoRequest));

      const resp = yield call(getIndicator, 'client', params, source.token);
      if (responseOK(resp)) {
        let newStatusId = resp.data.statusClientId;
        let newUpdatable = resp.data.updatable;
        let newAllApproved = resp.data.allApproved;
        yield put(
          indicatorsSuccess({
            ...currentIndicatorstClient.data,
            statusClientId: newStatusId,
            updatable: newUpdatable,
            allApproved: newAllApproved,
          })
        );
        yield put(
          saveClient({
            ...currentTradeAgreementClients,
            statusId: newStatusId,
            updatable: newUpdatable,
            allApproved: newAllApproved,
          })
        );
        yield put(secondValidationValidUserRequest());
      } else {
        console.warn('Por algún motivo extraño no encontramos el cliente');
      }
    } else {
      yield put(approveTradeAgreementAgreementFail());
    }
  } catch (e) {
    yield put(approveTradeAgreementAgreementFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchApproveTradeAgreementRequest() {
  yield takeLatest(APPROVE_TRADE_AGREEMENT_REQUEST, workerApproveTradeAgreementRequest);
}

function* workerRejectTradeAgreementRequest(action) {
  const currentIndicatorstClient = yield select((state) =>
    getFromStore(state, 'TRADE_AGREEMENTS_SELLOUT', 'views', 'indicators')
  );
  const currentTradeAgreementClients = yield select((state) =>
    getFromStore(state, 'TRADE_AGREEMENTS_SELLOUT', 'infoForAgreement', 'clientInfo')
  );

  //? Request params for success could be obtained from the store
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { data } = payload;
    const { clientId, territoryId, year } = data;
    const params = { clientId, territoryId, year };
    const response = yield call(rejectTradeAgreement, {
      data,
      cancelToken: source.token,
    });
    //const response = { status: 200, data: true };
    if (responseOK(response)) {
      yield call(openNotification, 'success', response.message);
      yield delay(2000);
      yield put(clientSelected({ clientId: data.clientId }));
      yield put(rejectTradeAgreementSuccess(response.data));
      const resp = yield call(getIndicator, 'client', params, source.token);
      if (responseOK(resp)) {
        let currentClient = clientId;
        const currentClients = yield select((state) => state?.APP?.catalogs?.clients?.data);
        let found = currentClients.find((client) => client.key === currentClient);
        let rest = currentClients.filter((client) => client.key !== currentClient);

        if (found) {
          let updateClient = { ...found, statusId: 5 };
          let newClients = [...rest, updateClient];
          let orderClients = newClients.sort(orderArrayObjects('place'));
          yield put(updateClients(orderClients));
        }

        // let paramsInfoRequest = {
        //   name: 'client',
        //   params: { clientId, territoryId, year },
        //   sourceId: role?.roleId === KAM ? 'KAM' : null,
        // };

        // let paramsIndicators = { name: 'client', params: { territoryId, clientId, year } };
        // yield put(indicatorsRequest(paramsIndicators));

        // yield put(infoRequest(paramsInfoRequest));

        let newStatusId = resp.data.statusClientId;
        yield put(saveClient({ ...currentTradeAgreementClients, statusId: newStatusId }));

        let newUpdatable = resp.data.updatable;
        let newAllApproved = resp.data.allApproved;
        yield put(
          indicatorsSuccess({
            ...currentIndicatorstClient.data,
            statusClientId: newStatusId,
            updatable: newUpdatable,
            allApproved: newAllApproved,
          })
        );
        yield put(
          saveClient({
            ...currentTradeAgreementClients,
            statusId: newStatusId,
            updatable: newUpdatable,
            allApproved: newAllApproved,
          })
        );
      } else {
        console.warn('Por algún motivo extraño no encontramos el cliente');
      }
    } else {
      yield put(rejectTradeAgreementegreementFail());
    }
  } catch (e) {
    yield put(rejectTradeAgreementegreementFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchRejectTradeAgreementRequest() {
  yield takeLatest(REJECT_TRADE_AGREEMENT_REQUEST, workerRejectTradeAgreementRequest);
}

function* workerApproveTradeAgreementKAMRequest(action) {
  const currentIndicatorstClient = yield select((state) =>
    getFromStore(state, 'TRADE_AGREEMENTS_SELLOUT', 'views', 'indicators')
  );
  const currentTradeAgreementClients = yield select((state) =>
    getFromStore(state, 'TRADE_AGREEMENTS_SELLOUT', 'infoForAgreement', 'clientInfo')
  );
  //? Request params for success could be obtained from the store
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { data } = payload;
    const { clientId, territoryId, year } = data;
    const params = { clientId, territoryId, year };
    const response = yield call(approveTradeAgreementByKam, {
      data,
      cancelToken: source.token,
    });
    if (responseOK(response)) {
      yield call(openNotification, 'success', response.message);
      yield delay(2000);
      yield put(clientSelected({ clientId: data.clientId }));
      yield put(approveTradeAgreementSuccessByKAM(response.data));
      const resp = yield call(getIndicator, 'client', params, source.token);
      if (responseOK(resp)) {
        let newStatusId = resp.data.statusClientId;
        let newUpdatable = resp.data.updatable;
        let newAllApproved = resp.data.allApproved;
        yield put(
          indicatorsSuccess({
            ...currentIndicatorstClient.data,
            statusClientId: newStatusId,
            updatable: newUpdatable,
            allApproved: newAllApproved,
          })
        );
        yield put(
          saveClient({
            ...currentTradeAgreementClients,
            statusId: newStatusId,
            updatable: newUpdatable,
            allApproved: newAllApproved,
          })
        );
      } else {
        console.warn('Por algún motivo extraño no encontramos el cliente');
      }
    } else {
      yield put(approveTradeAgreementAgreementFailByKAM());
    }
  } catch (e) {
    yield put(approveTradeAgreementAgreementFailByKAM());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchApproveTradeAgreementKAMRequest() {
  yield takeLatest(APPROVE_TRADE_AGREEMENT_KAM_REQUEST, workerApproveTradeAgreementKAMRequest);
}

function* workerRejectTradeAgreementKAMRequest(action) {
  const currentIndicatorstClient = yield select((state) =>
    getFromStore(state, 'TRADE_AGREEMENTS_SELLOUT', 'views', 'indicators')
  );
  const currentTradeAgreementClients = yield select((state) =>
    getFromStore(state, 'TRADE_AGREEMENTS_SELLOUT', 'infoForAgreement', 'clientInfo')
  );
  //? Request params for success could be obtained from the store
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { data } = payload;
    const { clientId, territoryId, year } = data;
    const params = { clientId, territoryId, year };
    const response = yield call(rejectTradeAgreementByKam, {
      data,
      cancelToken: source.token,
    });
    //const response = { status: 200, data: true };
    if (responseOK(response)) {
      // yield call(openNotification, 'success', response.message);
      // yield delay(2000);

      const currentClients = yield select((state) => state?.APP?.catalogs?.clients?.data);
      let currentClient = data?.clientId;
      let found = currentClients.find((client) => client.key === currentClient);
      let rest = currentClients.filter((client) => client.key !== currentClient);
      if (found) {
        let updateClient = { ...found, statusId: 4 };
        let newClients = [...rest, updateClient];
        let orderClients = newClients.sort(orderArrayObjects('place'));
        yield put(updateClients(orderClients));
      }

      yield openNotification('success', response.message);
      yield delay(2000);
      yield put(rejectTradeAgreementSuccessByKAM(response.data));
      yield put(clientSelected({ clientId: data.clientId }));

      // const localData = JSON.parse(localStorage.getItem('user'));
      // const { role } = localData;

      // let paramsInfoRequest = {
      //   name: 'client',
      //   params: { clientId, territoryId, year },
      //   sourceId: role?.roleId === KAM ? 'KAM' : null,
      // };

      // let paramsIndicators = { name: 'client', params: { territoryId, clientId, year } };
      // yield put(indicatorsRequest(paramsIndicators));
      // yield put(infoRequest(paramsInfoRequest));

      const resp = yield call(getIndicator, 'client', params, source.token);
      if (responseOK(resp)) {
        let newStatusId = resp.data.statusClientId;
        let newUpdatable = resp.data.updatable;
        let newAllApproved = resp.data.allApproved;
        yield put(
          indicatorsSuccess({
            ...currentIndicatorstClient.data,
            statusClientId: newStatusId,
            updatable: newUpdatable,
            allApproved: newAllApproved,
          })
        );
        yield put(
          saveClient({
            ...currentTradeAgreementClients,
            statusId: newStatusId,
            updatable: newUpdatable,
            allApproved: newAllApproved,
          })
        );
      } else {
        console.warn('Por algún motivo extraño no encontramos el cliente');
      }
    } else {
      yield put(rejectTradeAgreementegreementFailByKAM());
    }
  } catch (e) {
    yield put(rejectTradeAgreementegreementFailByKAM());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchRejectTradeAgreementKAMRequest() {
  yield takeLatest(REJECT_TRADE_AGREEMENT_KAM_REQUEST, workerRejectTradeAgreementKAMRequest);
}

function* workerGenerateAgreement(action) {
  const currentTradeAgreementClients = yield select((state) =>
    getFromStore(state, 'TRADE_AGREEMENTS_SELLOUT', 'infoForAgreement', 'clientInfo')
  );
  const currentIndicatorstClient = yield select((state) =>
    getFromStore(state, 'TRADE_AGREEMENTS_SELLOUT', 'views', 'indicators')
  );
  //? Request params for success could be obtained from the store
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const params = { ...payload };

    const response = yield call(generateAgreement, {
      payload,
      cancelToken: source.token,
    });

    if (responseOK(response)) {
      yield call(openNotification, 'success', response.message);
      yield put(generateradeAgreementSuccess(response.data));

      yield delay(500);
      yield put(clientSelected({ clientId: payload.clientId }));

      const resp = yield call(getIndicator, 'client', params, source.token);

      if (responseOK(resp)) {
        let currentClient = payload.clientId;
        const currentClients = yield select((state) => state?.APP?.catalogs?.clients?.data);
        let found = currentClients.find((client) => client.key === currentClient);
        let rest = currentClients.filter((client) => client.key !== currentClient);

        if (found) {
          let updateClient = { ...found, statusId: 1 };
          let newClients = [...rest, updateClient];
          let orderClients = newClients.sort(orderArrayObjects('place'));
          yield put(updateClients(orderClients));
        }
        let query = yield select((state) => state?.TRADE_AGREEMENTS_SELLOUT?.query);

        const { clientId, territoryId, year } = query;
        const localData = JSON.parse(localStorage.getItem('user'));
        const { role } = localData;

        let paramsInfoRequest = {
          name: 'client',
          params: { clientId, territoryId, year },
          sourceId: role?.roleId === KAM ? 'KAM' : null,
        };

        let paramsIndicators = { name: 'client', params: { territoryId, clientId, year } };
        yield put(indicatorsRequest(paramsIndicators));
        yield put(infoRequest(paramsInfoRequest));
        yield put(
          familyRequest({
            requestParams: params,
            sourceId: 'Agreements',
          })
        );
        //se hace el request de indicators y info
        let newStatusId = resp.data.statusClientId;
        let newUpdatable = resp.data.updatable;
        let newAllApproved = resp.data.allApproved;
        yield put(
          indicatorsSuccess({
            ...currentIndicatorstClient.data,
            statusClientId: newStatusId,
            updatable: newUpdatable,
            allApproved: newAllApproved,
          })
        );
        yield put(
          saveClient({
            ...currentTradeAgreementClients,
            statusId: newStatusId,
            updatable: newUpdatable,
            allApproved: newAllApproved,
          })
        );
      } else {
        console.warn('Por algún motivo extraño no encontramos el cliente');
      }
    } else {
      yield put(generateTradeAgreementAgreementFail());
    }
  } catch (error) {
    console.error(error);
    yield put(generateTradeAgreementAgreementFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchGenerateAgreement() {
  yield takeLatest(GENERATE_TRADE_AGREEMENT_REQUEST, workerGenerateAgreement);
}
