import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { reportRequest } from '../../../redux/actions/reports';
import { getMassiveUploadRequest } from '../../../redux/actions/getMassiveUpload';
import { Button, Modal, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './downloadReport.css';
import { openNotification } from 'common';
import DownloadButton from 'common/components/DownloadButton';

const CustomSpin = () => (
  <Spin
    indicator={
      <LoadingOutlined
        className="custom-spin"
        type="message"
        style={{
          fontSize: '16px',
          marginRight: '5px',
          display: 'inline-block',
        }}
        theme="outlined"
      />
    }
  />
);

function DownloadReport(props) {
  const {
    getMassiveUploadRequest: generateMassiveUpload,
    reportRequest: generateReport,

    current,
    reportIsDownloading,
    //getMassiveUploadIsDownloading,
    reportMassiveUploadIsLoading,
    query,
  } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const handleGenerateReport = useCallback(() => {
    let reportName = '';
    let date = new Date().toISOString().split('T')[0];
    const {
      regionId,
      lineId,
      subRegionId,
      family,
      clientId,
      productId,
      territoryId,
      PolicyTypeId: policyTypeId,
      year,
    } = query;
    let params = { year, name: `ACUERDO SELL OUT ${date}`, lineId };

    switch (current) {
      case 'regionId':
        reportName = 'region';
        params = { ...params, regionId };
        break;
      case 'lineId':
        reportName = 'businessLine';
        params = { ...params, regionId, lineId };
        break;
      case 'subRegionId':
        reportName = 'subRegion';
        params = { ...params, regionId, clientId, subRegionId };
        break;
      case 'territoryId':
        reportName = 'territory';
        params = { ...params, regionId, territoryId };
        break;
      case 'family':
        reportName = 'family';
        params = { ...params, clientId, family, territoryId, policyTypeId };
        break;
      case 'clientId':
        reportName = 'client';
        params = { ...params, clientId, territoryId };
        break;
      case 'productId':
        reportName = 'product';
        params = { ...params, clientId, productId, territoryId, policyTypeId };
        break;

      default:
        break;
    }

    generateReport({
      sourceId: reportName,
      params,
      onSuccess: () => {
        openNotification('success', 'El reporte de acuerdo comercial se generó exitosamente');
        setIsVisibleModal(false);
      },
    });
  }, [query, current, generateReport]);

  const handleGenerateMassiveUpload = useCallback(() => {
    let massiveUploadName = 'Massive Upload';
    let date = new Date().toISOString().split('T')[0];
    const {
      regionId,
      subRegionId,
      territoryId,
      clientId,
      family,
      lineId,
      year,
      productId,
      policyTypeId,
    } = query;
    let params = { year, name: `ACUERDO SELL OUT ${date}`, lineId };

    switch (current) {
      case 'regionId':
        massiveUploadName = 'region';
        params = { ...params, regionId };
        break;
      case 'lineId':
        massiveUploadName = 'businessLine';
        params = { ...params, regionId, lineId };
        break;
      case 'subRegionId':
        massiveUploadName = 'subRegion';
        params = { ...params, regionId, clientId, subRegionId };
        break;
      case 'territoryId':
        massiveUploadName = 'territory';
        params = { ...params, regionId, territoryId };
        break;
      case 'family':
        massiveUploadName = 'family';
        params = { ...params, clientId, family, territoryId, policyTypeId };
        break;
      case 'clientId':
        massiveUploadName = 'client';
        params = { ...params, clientId, territoryId };
        break;
      case 'productId':
        massiveUploadName = 'product';
        params = { ...params, clientId, productId, territoryId, policyTypeId };
        break;

      default:
        break;
    }
    generateMassiveUpload({
      //generateReport({
      //getReport({
      sourceId: massiveUploadName,
      params,
      onSuccess: () => {
        openNotification('success', 'El reporte de acuerdo comercial se generó exitosamente');
        setIsVisibleModal(false);
      },
    });
  }, [query, current, generateMassiveUpload]);

  if (query && query.year && Object.keys(query).length > 1) {
    return (
      <>
        <Button
          onClick={() => {
            setIsVisibleModal(true);
          }}
          style={{ background: '#00499A', color: 'white' }}
        >
          {' '}
          Exportar{' '}
        </Button>

        <Modal
          visible={isVisibleModal}
          className={'download__modal'}
          title={null}
          closable={!reportIsDownloading && !reportMassiveUploadIsLoading}
          onCancel={() => {
            setIsVisibleModal(false);
          }}
          footer={null}
        >
          <h4> Descargar Acuerdo comercial </h4>
          <p>¿En qué formato desea descargar la información del acuerdo comercial?</p>

          <footer className="modal__footer">
            <DownloadButton
              onClick={handleGenerateReport}
              className={'report-btn'}
              size="large"
              //label={report?.isDownloading ? 'Generando reporte' : 'Generar'}
              label={<>{reportIsDownloading && <CustomSpin />} Reporte</>}
              loading={reportIsDownloading}
            />

            <DownloadButton
              onClick={handleGenerateMassiveUpload}
              size="large"
              //label={report?.isDownloading ? 'Generando reporte' : 'Generar'}
              label={<> {reportMassiveUploadIsLoading && <CustomSpin />} Carga masiva</>}
              loading={reportMassiveUploadIsLoading}
            />
          </footer>
        </Modal>
      </>
    );
  } else {
    return null;
  }
}

function mapStateToProps(state) {
  return {
    current: state?.TRADE_AGREEMENTS_SELLOUT?.views?.current,
    reportIsDownloading: state?.TRADE_AGREEMENTS_SELLOUT?.report?.isDownloading ?? false,
    reportMassiveUploadIsLoading:
      state?.TRADE_AGREEMENTS_SELLOUT?.generateReport?.getReport?.isLoading ?? false,
    //TRADE_AGREEMENTS_SELLOUT.generateReport.getReport.isLoading

    query: state?.TRADE_AGREEMENTS_SELLOUT?.query,
  };
}

export default connect(mapStateToProps, {
  reportRequest,
  getMassiveUploadRequest,
})(DownloadReport);
