import {
  SECOND_VALIDATION_VALID_USER_REQUEST,
  secondValidationValidUserSuccess,
  secondValidationValidUserFail,
} from '../actions/secondValidation';
import { takeLatest, cancelled, put, call, select } from 'redux-saga/effects';
import { responseOK } from 'Domains/SalesChallengeRefactor/utils';
import instance from 'services/request';
import { secondValidationValidUser } from '../../services/secondValidation';
import { userIs } from 'utils';

function* workSecondValidationValidUserRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const query = yield select((state) => state?.TRADE_AGREEMENTS_SELLOUT?.query);
  /*
    {
    "state": "changed",
    "showBruteValue": true,
    "year": "2024",
    "regionId": 1,
    "lineId": 1,
    "subRegionId": 2,
    "territoryId": 7,
    "clientId": "96adead2-103c-41c3-9aa3-b50c6dd842f4",
    "businessRule": 3
}
    
    */

  const { territoryId, clientId } = query;
  const isSalesManager = userIs('Gerente de ventas');
  const regularCheck = territoryId && clientId;
  const salesManagerCheck = isSalesManager && clientId;

  const regularParams = { territoryId, clientId };
  let salesManagerParams = { clientId };
  if (territoryId) salesManagerParams = { ...salesManagerParams, territoryId };

  try {
    if (!regularCheck && !salesManagerCheck) {
      yield put(secondValidationValidUserFail());
      return;
    }

    const response = yield call(
      secondValidationValidUser,
      salesManagerCheck ? salesManagerParams : regularParams,
      source.token
    );

    if (responseOK(response)) {
      yield put(secondValidationValidUserSuccess(response.data));
    } else {
      yield put(secondValidationValidUserFail());
    }
  } catch (error) {
    if (cancelled()) {
      source.cancel();
    }
  }
}

export default function* watchSecondValidationValidUserRequest() {
  yield takeLatest(SECOND_VALIDATION_VALID_USER_REQUEST, workSecondValidationValidUserRequest);
}
