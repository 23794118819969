// *HU 2210
import {
  VALID_PRODUCTS_FOR_DELETING_REQUEST,
  validProductsForDeletingSuccess,
  validProductsForDeletingFail,
} from 'Domains/TradeAgreementsSellOut/redux/actions/validProductsForDeleting';
import { takeLatest, cancelled, put, call, select } from 'redux-saga/effects';
import { responseOK } from 'Domains/SalesChallengeRefactor/utils';
import instance from 'services/request';
import { getInfo } from '../../services/info';

function* workValidProductsForDeletingRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const query = yield select((state) => state?.TRADE_AGREEMENTS_SELLOUT?.query);
  const persistentTerritoryId = yield select(
    (state) => state?.TRADE_AGREEMENTS_SELLOUT?.persistentValues?.territory
  );
  const { clientId, year, PolicyTypeId: policyTypeId, family, Portafolio } = query;
  let hasMoreThanOneProducts = false;
  try {
    const clientParams = {
      territoryId: persistentTerritoryId,
      clientId,
      year,
      policyTypeId,
      Portafolio,
    };

    const familyParams = {
      territoryId: persistentTerritoryId,
      clientId,
      family,
      year,
      policyTypeId,
      Portafolio,
    };

    const familyResponse = yield call(getInfo, 'family', familyParams, source.token);

    if (responseOK(familyResponse)) {
      const totalProductsWithVolume = familyResponse.data.filter(
        (product) => product.totalVolume >= 0
      ).length;
      if (totalProductsWithVolume > 1) {
        hasMoreThanOneProducts = true;
      }
    }
    if (!hasMoreThanOneProducts) {
      const clientResponse = yield call(getInfo, 'client', clientParams, source.token);

      if (responseOK(clientResponse)) {
        const totalFamiliesWithVolume = clientResponse.data.filter(
          (fam) => fam.totalVolume >= 0
        ).length;
        if (totalFamiliesWithVolume > 1) {
          hasMoreThanOneProducts = true;
        }
      }

      yield put(validProductsForDeletingSuccess(hasMoreThanOneProducts ? 2 : 1));
    } else {
      yield put(validProductsForDeletingFail());
    }
  } catch (error) {
    yield put(validProductsForDeletingFail());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchValidProductsForDeletingRequest() {
  yield takeLatest(VALID_PRODUCTS_FOR_DELETING_REQUEST, workValidProductsForDeletingRequest);
}
