import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { processStepIdEnum, ResetButton, roleIdEnum } from 'common';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import PropTypes from 'prop-types';

import useWasRendered from 'hooks/useWasRendered';
import { FaUpload } from 'react-icons/fa';
import { Years, Clients, Territory, Family, Products } from 'RootModule/catalogs';
import { cleanTopFilters } from 'RootModule/redux/actions/catalogs';

import ActionsGroup from './ActionsGroup/ActionsGroup';
import ReviewActionsContainer from './ReviewActionsContainer';
import SecondLevelValidation from './SecondLevelValidation';
import useFilters from '../../../hooks/tradeAgreementsHooks/useFilters';
import {
  cleanIndicators,
  cleanInfo,
  cleanInfoForAgreement,
  clientSelected,
  getDaysLeftRequest,
  queryClean,
  querySave,
  setClientKey,
  setCurrentClientData,
  setCurrentView,
  territorySelected,
} from '../redux/actions';
import { handleResetBtn } from '../utils/handleResetButton';
import { setFormFieldValue } from '../utils/setFormFieldValue';
import SegmentBusinessRules from 'RootModule/catalogs/SegmentBusinessRules';
import { setFormFieldValueGroup } from '../utils/setFormFieldValueGroup';
import useUser from 'hooks/useUser';
import { RoleAccessControl } from 'common/components/accessControllers';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const layoutFilters = {
  xs: 8,
  sm: 8,
  md: 8,
  lg: 4,
  xl: 4,
};

const {
  GENERATE_TRADE_AGREEMENT,
  VALIDITY_PROCESS,
  UPLOAD_TRADE_AGREEMENT,
  TRADE_AGREEMENT_ADJUST,
} = processStepIdEnum;

const TopFiltersNationalClientsSellOut = (props) => {
  const {
    querySave: saveQuery,
    setCurrentView: setView,
    queryClean: cleanQuery,
    cleanTopFilters: cleanFilters,
    cleanInfo: infoClean,
    cleanInfoForAgreement: cleanAgreement,
    clientSelected: setClientSelected,
    cleanIndicators: indicatorsClean,
    onChangeActiveComponent,
    isNational,
    territorySelected: setTerritorySelected,
    getDaysLeftRequest: getDaysLeft,
    clientCatalogs,
    query,
    setClientKey: clientKeySet,
    setCurrentClientData: currentClientDataSet,
  } = props;
  // Customhook to set when component was rendered
  const user = useUser();
  const { wasRendered, setWasRendered } = useWasRendered();
  const [form] = Form.useForm();
  const [disabledFilters, setDisabledFilters] = useState({});
  const [allowRequest, setAllowRequests] = useState({});
  const [requestParamsSubscription, setRequestParamsSubscription] = useState({});
  // Dispatches cleanTopFilters
  const cleanAllFilters = useCallback(() => {
    cleanFilters();
  }, [cleanFilters]);
  // Effect to clean filters and get days left
  useEffect(() => {
    if (!wasRendered.current) {
      saveQuery({ state: 'idle', showBruteValue: true });
      cleanAllFilters();
      setWasRendered();
      getDaysLeft({ params: { processId: UPLOAD_TRADE_AGREEMENT } }); //6 días restantes
      getDaysLeft({ params: { processId: VALIDITY_PROCESS } }); //fecha de proceso
      getDaysLeft({ params: { processId: GENERATE_TRADE_AGREEMENT } });
      getDaysLeft({ params: { processId: TRADE_AGREEMENT_ADJUST } });
    }
  }, [saveQuery, wasRendered, setWasRendered, cleanAllFilters, getDaysLeft]);
  const location = useLocation();
  const [locationKey, setLocationKey] = useState(location.key);
  const {
    orchestrateFiltersChange,
    orchestrateFiltersReset,
    initialDisabledFilters,
    initialAllowRequest,
    initialRequestParamsSubscription,
  } = useFilters(isNational, false, query);
  // Effect to set initial states for filters
  useEffect(() => {
    if (initialDisabledFilters && initialAllowRequest && initialRequestParamsSubscription) {
      setDisabledFilters({ ...initialDisabledFilters });
      setAllowRequests({ ...initialAllowRequest });
      setRequestParamsSubscription({ ...initialRequestParamsSubscription });
      setWasRendered(true);
    }
  }, [
    initialAllowRequest,
    initialDisabledFilters,
    initialRequestParamsSubscription,
    wasRendered,
    setWasRendered,
  ]);

  const handleOnValuesChange = useCallback(
    ({ changedValues }) => {
      const currentChange = Object.keys(changedValues)[0];
      const formValues = form.getFieldsValue();
      //Sets active component
      onChangeActiveComponent('trade-agreements-sellout');
      // Fn to hadle filters changes
      orchestrateFiltersChange({
        changedValues,
        currentChange,
        form,
        formValues,
        requestParamsSubscription,
        setAllowRequests,
        setClientSelected,
        setDisabledFilters,
        setRequestParamsSubscription,
        setTerritorySelected,
        isNational,
        clientCatalogs,
      });
      let selectedClient = null;
      if (clientCatalogs && clientCatalogs.length > 0) {
        selectedClient = clientCatalogs.find(
          (client) => client.key === form.getFieldValue('clientId')
        );
      }
      // Setting view on current filter change
      if (currentChange === 'businessRule' && !selectedClient?.isNational) {
        setView('clientId');
      } else if (
        selectedClient?.isNational &&
        currentChange !== 'businessRule' &&
        currentChange !== 'family' &&
        currentChange !== 'productId'
      ) {
        setView('subRegionId');
      } else if (currentChange === 'businessRule' && selectedClient?.isNational) {
        setView('clientId');
      } else {
        setView(currentChange);
      }
      //Sharing this part of the query with other components
      const queryValues = form.getFieldsValue();

      saveQuery({ ...queryValues, state: 'changed' });
    },
    [
      clientCatalogs,
      form,
      onChangeActiveComponent,
      orchestrateFiltersChange,
      requestParamsSubscription,
      setClientSelected,
      setTerritorySelected,
      isNational,
      setView,
      saveQuery,
    ]
  );

  const setAutomaticallyFirstOption = (options, filterName = 'nonde') => {
    setFormFieldValue(form, options, filterName, options[0]?.key, handleOnValuesChange);
  };

  const setAutomaticallyAnOption = (options, optionKey = 1, filterName = 'none') => {
    setFormFieldValueGroup(form, options, filterName, optionKey, handleOnValuesChange);
  };

  const commonFilterProps = {
    allowClear: false,
  };

  // Fn to reset filters
  const handleResetButton = useCallback(() => {
    handleResetBtn(
      form,
      setDisabledFilters,
      setAllowRequests,
      cleanQuery,
      cleanAgreement,
      cleanAllFilters,
      infoClean,
      indicatorsClean,
      setRequestParamsSubscription,
      initialDisabledFilters,
      initialAllowRequest,
      orchestrateFiltersReset
    );
  }, [
    cleanAgreement,
    cleanAllFilters,
    cleanQuery,
    form,
    indicatorsClean,
    infoClean,
    initialAllowRequest,
    initialDisabledFilters,
    orchestrateFiltersReset,
  ]);

  useEffect(() => {
    if (location.key !== locationKey) {
      setLocationKey(location.key);
      handleResetButton();
    }
  }, [handleResetButton, location, locationKey]);

  // Sets clientkey n client data from form values
  const algo = (param) => {
    clientKeySet(param.clientId);
    currentClientDataSet(param);
  };
  // Returns boolean if current client is national
  const mustShowTerritory = () => {
    const getCurrentClientData = () => {
      if (!clientCatalogs || !form.getFieldValue().clientId) return null;
      return clientCatalogs.find((client) => client.key === form.getFieldValue().clientId);
    };
    const currentClient = getCurrentClientData();
    if (!currentClient) return false;
    return Boolean(currentClient.isNational);
  };

  return (
    <>
      <Form
        form={form}
        onValuesChange={(changedValues, allValues) => {
          handleOnValuesChange({ changedValues, allValues });
          algo(allValues);
        }}
        layout="horizontal"
        style={{ display: 'flex', justifyContent: 'start' }}
      >
        <Row>
          <Col {...layoutFilters}>
            <Years
              disabled={disabledFilters.year}
              askFromService={allowRequest.year ?? false}
              sourceId="Agreements-SellOut"
              requestParams={requestParamsSubscription?.['year']}
              onSuccess={(options) => setAutomaticallyFirstOption(options, 'year')}
              {...commonFilterProps}
            />
          </Col>

          <Col {...layoutFilters}>
            <Clients
              disabled={disabledFilters.clientId}
              askFromService={allowRequest.clientId ?? false}
              sourceId="Agreements-SellOut"
              requestParams={requestParamsSubscription?.['clientId']}
              {...commonFilterProps}
            />
          </Col>
          {mustShowTerritory() && (
            <Col {...layoutFilters}>
              <Territory
                disabled={disabledFilters.territoryId ?? null}
                askFromService={allowRequest.territoryId ?? null}
                sourceId="Agreements-SellOut"
                requestParams={requestParamsSubscription?.['territoryId']}
                {...commonFilterProps}
              />
            </Col>
          )}
          <Col {...layoutFilters}>
            <SegmentBusinessRules
              disabled={disabledFilters.businessRule}
              askFromService={allowRequest.businessRule ?? false}
              sourceId="Agreements-SellOut"
              requestParams={requestParamsSubscription?.['businessRule']}
              onSuccess={(options) => setAutomaticallyAnOption(options, '3', 'businessRule')}
              {...commonFilterProps}
            />
          </Col>
          <Col {...layoutFilters}>
            <Family
              disabled={disabledFilters.family}
              askFromService={allowRequest.family ?? false}
              sourceId="Agreements-SellOut"
              requestParams={requestParamsSubscription?.['family']}
              {...commonFilterProps}
            />
          </Col>

          <Col {...layoutFilters}>
            <Products
              disabled={disabledFilters.productId}
              askFromService={allowRequest.productId ?? false}
              sourceId="Agreements-SellOut"
              requestParams={requestParamsSubscription?.['productId']}
              {...commonFilterProps}
            />
          </Col>
        </Row>
      </Form>
      <Row gutter={32} justify="end">
        <Col>
          <ResetButton onClick={handleResetButton} />
        </Col>
        {user?.role?.roleId === roleIdEnum.SALES_AGENT && (
          <>
            <RoleAccessControl
              moduleKey="trade-agreements-sellout"
              page="register_trade-agreements-sellout"
            >
              <Col>
                <Link to={'/trade-agreements-sellout/register/trade-agreements-sellout/'}>
                  <span style={{ fontSize: '1.75rem', color: '#00499A' }}>
                    <FaUpload />
                  </span>
                </Link>
              </Col>
            </RoleAccessControl>
          </>
        )}
        <Col>
          <ReviewActionsContainer />
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <ActionsGroup />
          <SecondLevelValidation />
        </Col>
      </Row>
    </>
  );
};

TopFiltersNationalClientsSellOut.propTypes = {
  intialValues: PropTypes.object,
};

TopFiltersNationalClientsSellOut.defaultProps = {
  intialValues: {},
};

function mapStateToProps(state) {
  return {
    isNational: state.TRADE_AGREEMENTS_SELLOUT?.infoForAgreement?.clientInfo?.isNational,
    clientCatalogs: state?.APP?.catalogs?.clients?.data ?? false,
    query: state.TRADE_AGREEMENTS_SELLOUT?.query,
  };
}

export default connect(mapStateToProps, {
  querySave,
  setCurrentView,
  queryClean,
  cleanTopFilters,
  cleanInfo,
  cleanInfoForAgreement,
  cleanIndicators,
  clientSelected,
  territorySelected,
  getDaysLeftRequest,
  setClientKey,
  setCurrentClientData,
})(TopFiltersNationalClientsSellOut);
