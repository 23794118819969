import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BSelect } from 'common/components';
import { connect } from 'react-redux';
import { clientsRequest } from 'RootModule/redux/actions/catalogs';
import { handleOptionsProps } from 'Domains/SalesChallengeRefactor/helpers/helperForms';
import { filtersEnum } from 'RootModule/models/enums/filtersEnum';

import './style.css';

function Clients(props) {
  const {
    clients,
    clientsRequest: getClients,
    askFromService,
    name,
    requestParams,
    sourceId,
    optionsProps,
    ruleToClassName,
    onSuccess,
    onChange,
    mode,
    maxTagCount,
    ...other
  } = props;
  const [clientsData, setClientsData] = useState([]);

  useEffect(() => {
    if (askFromService) {
      getClients({
        requestParams,
        sourceId,
        onSuccess,
        mode,
      });
    }
  }, [getClients, askFromService, requestParams, sourceId, onSuccess, mode]);

  useEffect(() => {
    if (clients && clients.data[0] && clients.data[0].status) {
      setClientsData(clients.data.sort((a, b) => b.status - a.status));
    } else {
      setClientsData(clients.data);
    }
  }, [clients]);

  const handleOnChange = (value) => {
    if (onChange) onChange(value, { options: clients?.data, name: props.name });
  };

  return (
    <BSelect
      onChange={handleOnChange}
      isLoading={clients.isLoading}
      disabled={clients.isLoading}
      name={name}
      options={clientsData}
      optionsProps={handleOptionsProps(clients, optionsProps, ruleToClassName)}
      getPopupContainer={(trigger) => trigger.parentNode}
      mode={mode}
      maxTagCount={maxTagCount}
      {...other}
    />
  );
}

Clients.propTypes = {
  clientsRequest: PropTypes.func,
  clients: PropTypes.object,
  askFromService: PropTypes.bool,
  requestParams: PropTypes.object,
  sourceId: PropTypes.oneOf([
    'Agreements',
    'Agreements-SellOut',
    'Sales',
    'KAM',
    'TradeReport',
    'All',
    'BySoldTo',
    'Validators',
    'ValidatorsSellOut',
  ]),
  optionsProps: PropTypes.object,
  ruleToClassName: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
};

Clients.defaultProps = {
  clientsRequest: null,
  clients: { data: [], isLoading: false },
  askFromService: true,
  requestParams: {},
  sourceId: 'Sales',
  optionsProps: {},
  ruleToClassName: () => {
    return;
  },
  label: filtersEnum.clients.label,
  name: filtersEnum.clients.name,
};

function mapStateToProps(state) {
  return {
    clients: state.APP?.catalogs?.clients,
  };
}

export default connect(mapStateToProps, { clientsRequest })(Clients);
