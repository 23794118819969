import React, { useCallback, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useUser from 'hooks/useUser';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import getRootModule from 'RootModule';
import { roleIdEnum } from 'common';
import { layoutContext } from 'context';

import { TopFiltersNationalClientsSellOut } from '../../components';
import TopTradeAgreementsFiltersGroup from '../../components/TopTradeAgreementsFiltersGroup';
import ViewsManagement from '../../components/ViewsManagement';
import {
  cleanInfo,
  cleanInfoForAgreement,
  cleanRegisterTrade,
  queryClean,
  querySave,
  secondValidationValidUserRequest,
} from '../../redux/actions';
import getTradeAgreementsSellOutModule from '../../tradeSellOutModule';
import ConnectedDeleteTradeAgreementSellOutProductModal from 'Domains/TradeAgreementsSellOut/components/DeleteTradeAgreementSellOutProductModal';

const TradeAgreementsSellOut = () => {
  // Hooks for dispatching actions and selecting state from Redux
  const dispatch = useDispatch();
  const user = useUser(); // Custom hook to get user data from local storage
  const clientId = useSelector((state) => state?.TRADE_AGREEMENTS_SELLOUT?.query?.clientId ?? '');
  const territoryId = useSelector(
    (state) => state?.TRADE_AGREEMENTS_SELLOUT?.query?.territoryId ?? ''
  );
  const { onChangeActiveComponent } = useContext(layoutContext); // Context to manage layout state
  // Callback function to handle filter changes
  const filterChanged = useCallback(
    (view) => {
      onChangeActiveComponent(view);
      dispatch(cleanInfo());
      dispatch(queryClean());
      dispatch(querySave({ state: 'idle', showBruteValue: true }));
    },
    [dispatch, onChangeActiveComponent]
  );
  // Effects to clean up state on component mount
  useEffect(() => {
    dispatch(cleanRegisterTrade());
    dispatch(cleanInfoForAgreement());
    dispatch(cleanInfo());
  }, [dispatch]);
  // Effect to validate user on client or territory change
  useEffect(() => {
    dispatch(secondValidationValidUserRequest());
  }, [clientId, dispatch, territoryId]);
  // Function to render filters based on user role
  const renderFilters = () => {
    if (!user) return null;
    const { roleId } = user.role || {};
    if (
      [
        roleIdEnum.SALES_AGENT,
        roleIdEnum.SALES_MANAGER,
        roleIdEnum.AREA_ADMINISTRATOR,
        roleIdEnum.KAM,
        roleIdEnum.ADMIN_SELL_OUT,
      ].includes(roleId)
    ) {
      return <TopFiltersNationalClientsSellOut onChangeActiveComponent={filterChanged} />;
    }

    if (
      [roleIdEnum.ADMINISTRATOR, roleIdEnum.CONSULTANT_USER, roleIdEnum.AP_LEADER].includes(roleId)
    ) {
      return <TopTradeAgreementsFiltersGroup onChangeActiveComponent={filterChanged} />;
    }

    return null;
  };

  return (
    <>
      <div style={{ padding: '2px 0px 0px 8px' }}>
        {renderFilters()}
        <div style={{ marginTop: '1rem' }}>
          <ViewsManagement />
        </div>
      </div>
      <ConnectedDeleteTradeAgreementSellOutProductModal />
    </>
  );
};
// Dynamic module loader to load necessary Redux modules
const DynamicSellOutModule = () => (
  <DynamicModuleLoader modules={[getRootModule(), getTradeAgreementsSellOutModule()]}>
    <TradeAgreementsSellOut />
  </DynamicModuleLoader>
);

export default DynamicSellOutModule;
