import React, { useEffect, useState } from 'react';
import { Form, Col, Card, Skeleton } from 'antd';
import { connect } from 'react-redux';
import { getProductsPricesRequest } from '../../redux/actions/registerTrade';
import BInput from 'common/components/BInput';

import './style.css';

function ProductInfo(props) {
  const { showBruteValue, loading, brutePrice, netPrice, moq } = props;
  const [showPriceAndMoq, setShowPriceAndMoq] = useState(false);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setShowPriceAndMoq(true);
      }, 350);
    }
  }, [loading]);

  return (
    <Card className="bg-agreements bg bproduct-info">
      <h3>Información de producto</h3>
      <Form>
        {loading && (
          <>
            <Skeleton.Input style={{ width: 150 }} active size={'large'} />

            <Skeleton.Input style={{ width: 150 }} active size={'large'} />

            <Skeleton.Input style={{ width: 150 }} active size={'large'} />

            <Skeleton.Input style={{ width: 150 }} active size={'large'} />
          </>
        )}
        {showPriceAndMoq && (
          <>
            <Col span={24}>
              {showBruteValue && (
                <BInput
                  disabled={true}
                  label={'Precio bruto'}
                  name="price"
                  defaultValue={brutePrice}
                />
              )}
              {!showBruteValue && (
                <BInput
                  disabled={true}
                  label={'Precio neto'}
                  name="price"
                  defaultValue={netPrice}
                />
              )}
            </Col>
            <Col span={24}>
              <BInput disabled={true} label="MOQ" name="moq" defaultValue={moq} />
            </Col>
          </>
        )}
      </Form>
    </Card>
  );
}

function mapStateToProps(state) {
  return {
    productInfo: state?.TRADE_AGREEMENTS?.registerTrade?.productInfo || false,
    priceInfo: state?.TRADE_AGREEMENTS?.registerTrade?.priceInfo || false,
  };
}

ProductInfo.propTypes = {};

ProductInfo.defaultProps = {};

export default connect(mapStateToProps, { getProductsPricesRequest })(ProductInfo);
